



import { Vue, Component, Prop } from "vue-property-decorator";
@Component({
    components: {}
})
export default class FormItemInput extends Vue {
    @Prop({
        type: [String, Number, Object],
        required: false,
        default: () => ""
    })
    value: any; //表单数据

    @Prop({ type: Object, required: true, default: () => {} })
    formItemOpt: any; //表单参数

    mounted() {}
}
